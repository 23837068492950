import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import fr from './fr';
const getLanguageFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('lang') || 'en'; 
};
const resources = {
  en: en,
  fr: fr,
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getLanguageFromUrl(), 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
