import React from "react";
import { useSelector } from "react-redux";
const Loader = () => {
  const isLoading = useSelector((store) => store.isLoading);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.7)",
        zIndex: 99999999999,
        visibility: isLoading ? "visible" : "hidden",
      }}
    >
      <div>
        <div className='Landing_slider'>
          <div className="rounded-full  w-full h-full  flex justify-center items-center animtion_loader">
            <div class="page cinzel">
              <div class="txt page__txt" aria-label="KŌZĒ">
                <span className="txt__character" style={{ '--i': 0 }}>K</span>
                <span className="txt__character" style={{ '--i': 1 }}>Ō</span>
                <span className="txt__character" style={{ '--i': 2 }}>Z</span>
                <span className="txt__character" style={{ '--i': 3 }}>Ē</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
