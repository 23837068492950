const en = {
  translation: {
    "1. Property Management": "1. Property Management",
    "2. Financial & Contractual Details": "2. Financial & Contractual Details",
    "3. Property Usage & Access": "3. Property Usage & Access",
    "Support/FAQs": "Support/FAQs",
    "How can we assist you today?": "How can we assist you today?",
    "Search for topics…": "Search for topics…",
    "Frequently Asked Questions :-": "Frequently Asked Questions :-",
    "What do your services cover?": "What do your services cover?",
    "Our professional cleaning team is available 365 days a year, offering 5-star hotel-grade cleaning services.": "Our professional cleaning team is available 365 days a year, offering 5-star hotel-grade cleaning services.",
    "What is your experience?": "What is your experience?",
    "Over the past 6 years, we have built a diversified portfolio of over $20,000,000 worth of properties, generating millions of dollars in revenue under our management.": "Over the past 6 years, we have built a diversified portfolio of over $20,000,000 worth of properties, generating millions of dollars in revenue under our management.",
    "How do you filter guests?": "How do you filter guests?",
    "Yes! We require:": "Yes! We require:",
    "Official Government ID(s)": "Official Government ID(s)",
    "Recommendations from past hosts": "Recommendations from past hosts",
    "Verified by Airbnb": "Verified by Airbnb",
    "A security deposit": "A security deposit",
    "How does it work with cleaning fees?": "How does it work with cleaning fees?",
    "We always charge the guest for the cleaning fees.": "We always charge the guest for the cleaning fees.",
    "What if we need a handyman?": "What if we need a handyman?",
    "Over the past 6 years, we have built a great network of professionals. If needed, we will coordinate everything for you, and they will invoice you directly for full transparency.": "Over the past 6 years, we have built a great network of professionals. If needed, we will coordinate everything for you, and they will invoice you directly for full transparency.",
    "How do you create the Airbnb listing?": "How do you create the Airbnb listing?",
    "With our experience, we create the most attractive listing with captivating photos, descriptions, titles, and overall aesthetics.": "With our experience, we create the most attractive listing with captivating photos, descriptions, titles, and overall aesthetics.",
    "How will I get paid?": "How will I get paid?",
    "We will add you as a payee on Airbnb, and you’ll receive a payout 24 hours after the guest checks in, directly from Airbnb.": "We will add you as a payee on Airbnb, and you’ll receive a payout 24 hours after the guest checks in, directly from Airbnb.",
    "How does it work for damages?": "How does it work for damages?",
    "We handle all claims for you on Airbnb with our $1,000,000 Superhost insurance for each booking. Airbnb also offers a great Superhost program called Air Cover.": "We handle all claims for you on Airbnb with our $1,000,000 Superhost insurance for each booking. Airbnb also offers a great Superhost program called Air Cover.",
    "What happens if we ever want to stop the engagement?": "What happens if we ever want to stop the engagement?",
    "We offer all clients an open-ended contract, meaning you can stop whenever you like, with no penalties.": "We offer all clients an open-ended contract, meaning you can stop whenever you like, with no penalties.",
    "What are your rates?": "What are your rates?",
    "Rates vary depending on the type and size of your property. Please call or text us for more details.": "Rates vary depending on the type and size of your property. Please call or text us for more details.",
    "How do you optimize pricing?": "How do you optimize pricing?",
    "By analyzing the rental activity of comparable properties, we customize the nightly rates to maximize your monthly revenues and occupancy rate.": "By analyzing the rental activity of comparable properties, we customize the nightly rates to maximize your monthly revenues and occupancy rate.",
    "How can I contact you?": "How can I contact you?",
    "Phone: 514-758-4581": "Phone: 514-758-4581",
    "What if I want to use the property for myself?": "What if I want to use the property for myself?",
    "Whenever you want to use it, just let us know, and we'll block the dates for you - no charge, of course.": "Whenever you want to use it, just let us know, and we'll block the dates for you - no charge, of course.",
    "What access will I have?": "What access will I have?",
    "We add all our clients as co-hosts on Airbnb. You’ll have full access to reservations, pricing, transaction history, and calendar availability.": "We add all our clients as co-hosts on Airbnb. You’ll have full access to reservations, pricing, transaction history, and calendar availability.",
    "What if we bring a client who is interested in renting our chalet at a time that it is not already rented (not family and friends)?": "What if we bring a client who is interested in renting our chalet at a time that it is not already rented (not family and friends)?",
    "It would be best to direct them to the Airbnb listing to build the reviews, be covered by Airbnb’s $1,000,000 insurance, and undergo rigorous KYC (Know Your Customer) checks.": "It would be best to direct them to the Airbnb listing to build the reviews, be covered by Airbnb’s $1,000,000 insurance, and undergo rigorous KYC (Know Your Customer) checks.",
    "How does your customer service work?": "How does your customer service work?",
    "Our team is available to answer any questions or inquiries at all times.": "Our team is available to answer any questions or inquiries at all times.",
    "How do you handle inventory reporting?": "How do you handle inventory reporting?",
    "We will alert you when inventory is low (soap, toilet paper, etc.).": "We will alert you when inventory is low (soap, toilet paper, etc.).",
    "How do you report damages?": "How do you report damages?",
    "In the rare case of damage from a guest, we handle the claims on your behalf with Airbnb’s Air Cover $1,000,000 insurance.": "In the rare case of damage from a guest, we handle the claims on your behalf with Airbnb’s Air Cover $1,000,000 insurance.",
    "Not what you are looking for? Feel free to contact us.": "Not what you are looking for? Feel free to contact us.",
    "":"",
    "Experience the":"Experience the",
    "Ultimate Getaway":"Ultimate Getaway",
    "We offer more than just a place to stay.":"We offer more than just a place to stay.",
    "Why Choose KŌZĒ?":"Why Choose KŌZĒ?",
    "Unmatched Comfort":"Unmatched Comfort",
    "Every KŌZĒ property is thoughtfully designed with your":"Every KŌZĒ property is thoughtfully designed with your",
    "comfort in mind. From luxurious bedding to fully":"comfort in mind. From luxurious bedding to fully",
    "equipped kitchens and cozy living spaces, we ensure that":"equipped kitchens and cozy living spaces, we ensure that",
    "all the comforts of home are at your fingertips.":"all the comforts of home are at your fingertips.",
    "Stunning Locations":"Stunning Locations",
    "Our properties offer breathtaking views, serene surroundings, and":"Our properties offer breathtaking views, serene surroundings, and",
    "easy access to local attractions. Whether you are seeking a":"easy access to local attractions. Whether you are seeking a",
    "peaceful retreat or an adventure-filled escape, KŌZĒ has the":"peaceful retreat or an adventure-filled escape, KŌZĒ has the",
    "perfect location for you.":"perfect location for you.",
    "Seamless Stays":"Seamless Stays",
    "We believe in making your stay as hassle-free as possible.":"We believe in making your stay as hassle-free as possible.",
    "With keyless entry, easy check-ins, and responsive":"With keyless entry, easy check-ins, and responsive",
    "customer service, your experience with KŌZĒ is designed":"customer service, your experience with KŌZĒ is designed",
    "to be smooth and enjoyable from start to finish.":"to be smooth and enjoyable from start to finish.",
    "Luxurious Amenities":"Luxurious Amenities",
    "Enjoy premium amenities such as hot tubs,":"Enjoy premium amenities such as hot tubs,",
    "fireplaces, and outdoor spaces that make your stay":"fireplaces, and outdoor spaces that make your stay",
    "special.":"special.",
    "Personalized":"Personalized",
    "Recommendations":"Recommendations",
    "Get local tips and recommendations directly from":"Get local tips and recommendations directly from",
    "our team to make the most of your stay.":"our team to make the most of your stay.",
    "Flexible Booking":"Flexible Booking",
    "Whether you are planning a weekend getaway or a":"Whether you are planning a weekend getaway or a",
    "longer stay, our flexible booking options cater to":"longer stay, our flexible booking options cater to",
    "your needs.":"your needs.",
    "Explore Our Properties":"Explore Our Properties",
    "Discover the diverse range of properties KŌZĒ has to":"Discover the diverse range of properties KŌZĒ has to",
    "offer, each with its own unique charm and character.":"offer, each with its own unique charm and character.",
    "From cozy cabins to modern units, explore our listings to":"From cozy cabins to modern units, explore our listings to",
    "find your perfect getaway.":"find your perfect getaway.",
    "Quebec, Canada":"Quebec, Canada",
    "Studio | 1 Bathroom":"Studio | 1 Bathroom",
    "Toronto, Canada":"Toronto, Canada",
    "Studio | 1 Bathroom":"Studio | 1 Bathroom",
    "British Columbia, Canada":"British Columbia, Canada",
    "Studio | 1 Bathroom":"Studio | 1 Bathroom",
    "Dubai, United Arab Emirates":"Dubai, United Arab Emirates",
    "Studio | 1 Bathroom":"Studio | 1 Bathroom",
    "What You will Love":"What You will Love",
   
  }
};

export default en;
