const initialCurrencyState = 'CAD ($)'; 

const CurrencyReducer = (state = initialCurrencyState, action) => {
  switch (action.type) {
    case 'SET_CURRENCY':
      return action.payload;
    default:
      return state;
  }
};

export default CurrencyReducer;
