import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Color";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../DropDown/CustomDropdownImg";
import CustomDropdowns from "../DropDown/CustomDropdown";
import i18n from "../LanguageSelector/i18n";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../Config/Actions/LanguageActions";
import { setcurrency } from "../../Config/Actions/CurrencyActions";
import { useNavigate } from 'react-router-dom';
import {  useLocation } from "react-router-dom";
const languagesdata = [
  {
    Image: Icons.kingdom,
    Label: "English (ca)",
  },
  {
    Image: Icons.francefag,
    Label: "Français",
  },
];
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate(); 
  const location = useLocation();

  const changeLanguage = (lng) => {
    let languageCode = lng;
    if (lng === "English (ca)") {
      languageCode = "en";
   
    } else if (lng === "Français") {
      languageCode = "fr";
    }
    i18n.changeLanguage(languageCode);
    localStorage.setItem("language", languageCode);
    localStorage.setItem("language1", lng);
    dispatch(setLanguage(lng));
    const currentPath = location.pathname.split('/');
    let newPath;
    if (location.pathname === '/' || location.pathname === "/en" || location.pathname === "/fr") {
      navigate(`/${languageCode}`);
    } else {
      newPath = `/${currentPath[1]}/${languageCode}`;
      navigate(newPath);
    } 
   
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    const savedLanguage1 = localStorage.getItem("language1");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      dispatch(setLanguage(savedLanguage1));
    }
  }, []);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const shouldShowHeader =
        currentScrollY === lastScrollY || currentScrollY <= 100;
      setIsHeaderVisible(shouldShowHeader);
      setLastScrollY(currentScrollY);
      setIsMenuOpen(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const currency = useSelector((state) => state.currency);
  const { t } = useTranslation();
  const handleDropdowns = (n) => {
    dispatch(setcurrency(n));
  };

  const handlebook = () => {
    if (language === "English (ca)") {
      window.location.href = "https://reservations.kozestays.com/";
    } else {
      window.location.href = "https://reservations.kozestays.com/?lang=fr";
    }
  };
  return (
    <header
      className={`bg-black shadow-md py-7   ${isHeaderVisible ? "" : "fixed w-full z-50"
        }`}
    >
      <nav className="container mx-auto flex justify-between items-center  relative capitalize">
        <div
          className="flex space-x-4 text-white items-center gap-12 max-[818px]:gap-4 z-30 relative  max-[660px]:w-full max-[660px]:justify-between "
          style={{ color: Colors.lightgoladn }}
        >
          <div className="z-30 relative">
            <img
              onClick={toggleMenu}
              src={Icons.Menu_left}
              className="h-5 cursor-pointer max-[520px]:h-5"
            />
          </div>
          <div
            className="flex-1 max-[660px]:flex justify-center absolute left-0 w-full -z-0 hidden m-0  "
            style={{ margin: "0px" }}
          >
            <Link to="" className="flex items-center">
              <img
                src={Icons.Koze_Logo}
                alt="Logo"
                className="h-12 w-auto max-[520px]:h-9  max-[342px]:h-7 "
              />
            </Link>
          </div>
          <button
            style={{ backgroundColor: Colors.lightgoladn }}
            onClick={handlebook}
            className="text-black rounded-full py-1 px-5  max-[520px]:px-3   Aileron font-semibold font-24 white_class z-30 relative max-[342px]:px-[6px]"
          >
            <div className="max-[420px]:w-[34px] overflow-hidden h-6">
              {t("Book Now")}
            </div>
          </button>
        </div>
        <nav
          className={`fixed top-[88px] left-0 bg-black text-white p-4 z-[999] transition-transform transform ${isMenuOpen ? "translate-x-0" : "-translate-x-full"
            } w-[300px]  max-[818px]:w-full h-full`}
        >
          <div
            className="text-white cursor-pointer font-semibold text-2xl absolute right-5"
            onClick={toggleMenu}
          >
            X
          </div>
          <ul>
            <li>
              <Link onClick={toggleMenu}
                to="/"
                className="block py-2 px-4  Optima  font-semibold"
              >
                {t("Home")}
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu}
                to="/AboutUs"
                className="block py-2 px-4  Optima  font-semibold"
              >
                {t("About Us")}
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu}
                to="/StaywithUs"
                className="block py-2 px-4  Optima  font-semibold"
              >
                {t("Stay with Us")}
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu}
                to="/ManageYourProperty"
                className="block py-2 px-4  Optima  font-semibold"
              >
                {t("Manage Your Property")}
              </Link>
            </li>
            <li>
              <Link
                to="/Team" onClick={toggleMenu}
                className="block py-2 px-4  Optima  font-semibold"
              >
                {t("Team")}
              </Link>
            </li>
            <li
              onClick={() => {
                toggleMenu();
              }}
            >
              <Link
                to="/ContactUs"
                className="block py-2 px-4  Optima  font-semibold"
              >
                {t("Contact")}
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu}
                to="/Faq"
                className="block py-2 px-4  Optima  font-semibold"
              >
                {t("FAQs")}
              </Link>
            </li>
            <div className="max-[1190px]:flex hidden gap-4 mt-3 ml-3 max-[818px]:float-left max-[818px]:mr-4">
              <div>
                <Link to="https://www.facebook.com/kozestays/" target="_blank" >
                  <img src={Icons.fb} className="h-6 cursor-pointer" />
                </Link>
              </div>
              <div>
                <Link to="https://www.linkedin.com/company/kozestays" target="_blank" >
                  <img src={Icons.in} className="h-6 cursor-pointer" />
                </Link>
              </div>
            </div>
            <div className="max-[818px]:flex hidden gap-4 mt-3 ml-3">
              <div>
                <Link to="https://www.instagram.com/kozestays/" target="_blank">
                  <img src={Icons.instagram} className="h-6 cursor-pointer" />
                </Link>
              </div>
              <div>
                <Link to="/ContactUs">
                  <img src={Icons.contactmobail} className="h-6 cursor-pointer" />
                </Link>
              </div>
            </div>
            <div className="max-[660px]:flex  hidden gap-4 mt-4 ml-3">
              <span className="header_currency custom-ropy text-sm font-semibold font-24 text-white ">
                <CustomDropdowns
                  dropDownLabel={currency}
                  items={[
                    "USD ($)",
                    "CAD ($)",
                    "Pound(₤)",
                    "Peso($)",
                    "Euro(€)",
                    "UAE(د.إ)",
                  ]}
                  onSelect={handleDropdowns}
                />
              </span>
              <div className="">
                <CustomDropdown
                  dropDownLabel={language}
                  items={languagesdata}
                  onSelect={changeLanguage}
                />
              </div>
            </div>
          </ul>
        </nav>
        <div className="flex-1 flex justify-center absolute left-0 w-full -z-0  max-[660px]:hidden  ">
          <Link to="" className="flex items-center">
            <img src={Icons.Koze_Logo} alt="Logo" className="h-12 w-auto" />
          </Link>
        </div>
        <div className="flex items-center space-x-4 text-white gap-3 z-30 ">
          <span className="header_currency custom-ropy text-sm font-semibold font-24 text-white max-[660px]:hidden">
            <CustomDropdowns
              dropDownLabel={currency}
              items={[
                "USD ($)",
                "CAD ($)",
                "Pound(₤)",
                "Peso($)",
                "Euro(€)",
                "UAE(د.إ)",
              ]}
              onSelect={handleDropdowns}
            />
          </span>
          <div className="max-[660px]:hidden">
            <CustomDropdown
              dropDownLabel={language}
              items={languagesdata}
              onSelect={changeLanguage}
            />
          </div>
          <div className="max-[818px]:hidden">
            <Link to="/ContactUs">
              <img src={Icons.contactmobail} className="h-6 cursor-pointer" />
            </Link>
          </div>
          <div className="max-[818px]:hidden">
          
            <Link to="https://www.instagram.com/kozestays/" target="_blank">
              <img src={Icons.instagram} className="h-6 cursor-pointer" />
            </Link>
          </div>
          <div className="max-[1190px]:hidden">
            <Link to="https://www.facebook.com/kozestays/" target="_blank" >
              <img src={Icons.fb} className="h-6 cursor-pointer" />
            </Link>
          </div>
          <div className="max-[1190px]:hidden">
            <Link to="https://www.linkedin.com/company/kozestays" target="_blank" >
              <img src={Icons.in} className="h-6 cursor-pointer" />
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
