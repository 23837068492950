
const Colors = {
  black: "#1B1C1C",
  IvoryBeige: "#EAECE2",
  Gray: "#7E7E7E",
  white:"white",
  Yellow:"#FFE6B8",
  lightpink:"#FFCCCC",
  dsg:"#E3E0FF",
  lightgray:"#7E7E7E",
  Goladn:"#BD8833",
  lightgoladn:"#E0C9AA"
};

export default Colors;
