import axios from "axios";

const NewsLetterSubscribe = async (formData) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_MAIL_NEWSLETTER,
      {
        email: formData.email,
        name: formData.name,
        phone: formData.phone,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export default NewsLetterSubscribe;
