export const initGA = () => {
  return new Promise((resolve) => {
    (function() {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-M83YMPYBV3';
      document.head.appendChild(script);
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', 'G-M83YMPYBV3');
        resolve();  // Resolve the promise once gtag is loaded
      };
    })();
  });
};

export const trackPageLoadTime = () => {
  if (window.performance) {
    window.addEventListener('load', () => {
      const loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      window.gtag('event', 'timing_complete', {
        name: 'load_time',
        value: loadTime,
        event_category: 'Page Load',
      });
    });
  }
};
initGA().then(() => {
  trackPageLoadTime();  // Ensure GA is initialized before tracking page load time
});
