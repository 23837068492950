import React, { useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { setLoadingFalse } from "../Config/Actions/loadingActions";

const PDFViewer = () => {
  const { propertyName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileUrl = `/pdf/${propertyName}.pdf`;
  const onNavigateHome = () => {
    navigate("/");
  };
  const onDocumentLoad = () => {
    dispatch(setLoadingFalse());
  };
  return (
    <div
      className="pdf-viewer"
      style={{
        height: "calc(100vh - 50px)",
        width: "100%",
        backgroundColor: "#000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          width: "80%",
          maxWidth: "1200px",
          height: "100%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#000",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
        >
          <Viewer
            fileUrl={fileUrl}
            withCredentials={false}
            renderLoader={()=><div style={{background:"#000",height:"100%",width:"100%"}}></div>}
            onDocumentLoad={()=>onDocumentLoad()}
            renderError={(error) => onNavigateHome()}
          />
        </Worker>
      </div>
    </div>
  );
};

export default PDFViewer;
