import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer'; 
import languageReducer from './languageReducer'; 
import currencyReducer from './currencyReducer'; 

const rootReducer = combineReducers({
  isLoading: loadingReducer,
  language: languageReducer,
  currency:currencyReducer,
});

export default rootReducer;
