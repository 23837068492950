import React, {  useState } from "react";
import "./CustomDropDownStyle.css";
import { useTranslation } from "react-i18next";
function CustomDropdownImg({ items, onSelect, dropDownLabel }) {
  const [isOpen, setIsOpen] = useState(false);
  const [cur_section, setCurSection] = useState("");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const { t } = useTranslation();
  const handleItemClick = (item) => {
    setIsOpen(false);
    onSelect(item);
    setCurSection(item);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      onMouseLeave={() => handleMouseLeave()}
      onClick={toggleDropdown}
      className="custom-dropdown"
    >
      <div>
        <div
          onClick={toggleDropdown}
          style={{ borderRadius: '5px',
          padding:"0px 5px 0px 5px",
            border: '1px solid #FFF'}}
        >
          {dropDownLabel === "Français" ? "FR" : "EN"}
        </div>
      </div>
      {isOpen && (
        <div style={{ position: "absolute", width: "100%", left: "0px" }}>
          <div style={{ height: 10 }}></div>
          <ul
            className="dropdown-menu"
            style={{ width: "fit-content", color: "white" }}
          >
            {items.map((item) => (
              <li
                className={item === cur_section ? "" : ""}
                key={item}
                onClick={() => handleItemClick(item.Label)}
              >
                <div className="flex items-center ">
                  <div className="text-white w-20">{t(item.Label)}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
export default CustomDropdownImg;
