import React, { useState, useEffect } from "react";
import Colors from "../../Constants/Color";
import Icons from "../../Constants/Icons";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import CustomDropdown from "../DropDown/CustomDropdown";
import { useTranslation } from "react-i18next";
import i18n from "../LanguageSelector/i18n";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../Config/Actions/LanguageActions";
import { setcurrency } from "../../Config/Actions/CurrencyActions";
import { useNavigate } from 'react-router-dom';
import {  useLocation } from "react-router-dom";
const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const location = useLocation();
  const changeLanguage = (lng) => {
    let languageCode = lng;
    if (lng === "English (ca)") {
      languageCode = "en";
    } else if (lng === "Français") {
      languageCode = "fr";
    }
    i18n.changeLanguage(languageCode);
    localStorage.setItem("language", languageCode);
    localStorage.setItem("language1", lng);
    dispatch(setLanguage(lng));
    const currentPath = location.pathname.split('/');
    let newPath;
    if (location.pathname === '/' || location.pathname === "/en" || location.pathname === "/fr") {
      navigate(`/${languageCode}`);
    } else {
      newPath = `/${currentPath[1]}/${languageCode}`;
      navigate(newPath);
    } 
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    const savedLanguage1 = localStorage.getItem("language1");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      dispatch(setLanguage(savedLanguage1));
    }
  }, []);
  const { t } = useTranslation();
  const handleDropdown = (n) => {
    dispatch(setcurrency(n));
  };
  const language = useSelector((state) => state.language);
  const currency = useSelector((state) => state.currency);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleChange = (e) => {
    setEmail(e.target.value);

    if (!validateEmail(e.target.value)) {
      setError(t("Please enter a valid email address"));
      setSuccessMessage("");
    } else {
      setError("");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError(t("Please enter a valid email address"));
      setSuccessMessage("");
      return;
    }
    setSuccessMessage(t("Thank you for signing up!"));
    setTimeout(() => {
      setSuccessMessage(t(""));
    }, 3000);
    setEmail("");
    setError("");
  };
  return (
    <div>
      <div style={{ backgroundColor: Colors.black, padding: "70px 0px" }}>
        <div className="container">
          <div className="Reserved_flex" style={{ color: Colors.white }}>
            <div className="w_footer" style={{ width: "50%" }}>
              <ScrollAnimation animateIn="none">
                <div
                  style={{ marginTop: "0px" }}
                  className="cinzel font-32 text-left"
                >
                  {t("KŌZĒ UP!")}
                </div>
                <div className="font-24 Optima text-ellipsis placeholder:text-ellipsis my-4 text-left">
                  {t("Enter your email address to be notified about promotions, updates, and more!")}
                </div>
                <div className="Newsletter">
                  <div className={`KozeEmail ${error ? "" : ""}`}>
                    <input
                      type="email"
                      placeholder={t("Enter Your Email Address")}
                      value={email}
                      onChange={handleChange}
                      className="font-24 text-ellipsis placeholder:text-ellipsis placeholder:text-yellow-200 custom-placeholder"
                    />
                  </div>
                  <div className="signup">
                    <button
                      onClick={handleSubmit}
                      className="custom-btn btn-14 Optima white_class "
                    >
                      {t("Sign Up")}
                    </button>
                  </div>
                </div>
                {error && (
                  <div className="error-message Optima font-24 text-left">
                    {t(error)}
                  </div>
                )}
                {successMessage && (
                  <div className="Optima font-24 success-message text-left">
                    {t(successMessage)}
                  </div>
                )}
              </ScrollAnimation>
            </div>
          </div>
          <div className=" mt-10 flex justify-between flex-wrap gap-10  ">
            <ScrollAnimation animateIn="none">
              <div className="text-white">
                <ul
                  className="Optima text-xl capitalize grid gap-1"
                  style={{ fontWeight: "400" }}
                >
                  <li className="mb-4 font-semibold">
                    <Link to="/">{t("company")}</Link>
                  </li>
                  <li>
                    <Link to="/AboutUs">{t("about us")}</Link>
                  </li>
                  <li>
                    <Link to="/StaywithUs"> {t("stay with us")}</Link>
                  </li>
                  <li>
                    <Link to="/ManageYourProperty">
                      {t("manage your property")}
                    </Link>
                  </li>
                </ul>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="none">
              <div className="text-white">
                <ul
                  className="Optima text-xl capitalize grid gap-1"
                  style={{ fontWeight: "400" }}
                >
                  <li className="mb-4 font-semibold">
                    <Link> {t("support")}</Link>
                  </li>
                  <li>
                    <Link to="/Faq">{t("FAQs")}</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">{t("contact")}</Link>
                  </li>
                </ul>
              </div>
            </ScrollAnimation>
            <div>
              <ul
                className="Optima text-xl capitalize text-white grid gap-1"
                style={{ fontWeight: "400" }}
              >
                <li className=" font-semibold">
                  <Link to="#" className="">
                    {t("socials")}
                  </Link>
                </li>
              </ul>
              <div className="flex gap-6 mt-4">
                <div>
                  <ScrollAnimation animateIn="none">
                    <Link
                      to="https://www.facebook.com/kozestays/"
                      target="_blank"
                    >
                      <img
                        src={Icons.facebook}
                        className="w-3 cursor-pointer"
                      />
                    </Link>
                  </ScrollAnimation>
                </div>
                <div>
                  <ScrollAnimation animateIn="none">
                    <Link
                      to="https://www.instagram.com/kozestays/"
                      target="_blank"
                    >
                      <img
                        src={Icons.instagramwhite}
                        className="w-6 cursor-pointer"
                      />
                    </Link>
                  </ScrollAnimation>
                </div>
                <div>
                  <ScrollAnimation animateIn="none">
                    <Link
                      to="https://www.linkedin.com/company/kozestays"
                      target="_blank"
                    >
                      <img src={Icons.in} className="w-6 cursor-pointer" />
                    </Link>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="lg:mr-10 mr-0">
              <div
                className="text-xl   font-semibold text-white Optima capitalize mb-1"
                style={{ fontWeight: "700" }}
              >
                
                {t("language")}
              </div>
              <div className="bg-[#E0C9AA] white_class text-black custom-footer Aileron w-[200px] max-[768px]:w-[160px] z-30 relative  rounded-3xl px-5 py-1">
                <CustomDropdown
                  dropDownLabel={language}
                  items={["English (ca)", "Français"]}
                  onSelect={changeLanguage}
                />
              </div>
              <div
                className=" font-semibold text-white Optima text-xl capitalize mt-5 mb-1"
                style={{ fontWeight: "700" }}
              >
                
                {t("currency")}
              </div>
              <div className="bg-[#E0C9AA] white_class text-black custom-footer custom-ropy Aileron w-[200px] max-[768px]:w-[160px] rounded-3xl px-5  py-1">
                <CustomDropdown
                  dropDownLabel={currency}
                  items={[
                    "USD ($)",
                    "CAD ($)",
                    "Pound(₤)",
                    "Peso($)",
                    "Euro(€)",
                    "UAE(د.إ)",
                  ]}
                  onSelect={handleDropdown}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 max-[800px]:grid-cols-1 mt-14">
            <div
              className="font-24 Optima text-white capitalize"
              style={{ color: "white" }}
            >
              
              {t("© 2024 KŌZĒ All Rights Reserved.")}
            </div>
            <div
              className="font-24 Optima text-white text-right justify-end max-[800px]:justify-start max-[800px]:mt-4 flex-wrap"
              style={{
                color: "white",
                display: "flex",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="ml-4 max-[800px]:ml-0 capitalize"
              >
                <Link to={"/TermsCondition"}>
                  {t("Terms & Conditions")}
                </Link>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="ml-4 mr-[50px] max-[800px]:mr-0 capitalize"
              >
                <Link to={"/PrivacyPolicy"}>{t("Privacy Policy")}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
